@font-face {
  font-family: "fontello";
  src: url("../font/fontello.eot?51869443");
  src: url("../font/fontello.eot?51869443#iefix") format("embedded-opentype"),
    url("../font/fontello.woff2?51869443") format("woff2"),
    url("../font/fontello.woff?51869443") format("woff"),
    url("../font/fontello.ttf?51869443") format("truetype"),
    url("../font/fontello.svg?51869443#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?51869443#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  /* margin-right: 0.2em; */
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* margin-left: 0.2em; */

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-history:before {
  content: "\e800";
} /* '' */
.icon-bookmark:before {
  content: "\e801";
} /* '' */
.icon-chevron-left:before {
  content: "\e802";
} /* '' */
.icon-chevron-right:before {
  content: "\e803";
} /* '' */
.icon-circle-help:before {
  content: "\e804";
} /* '' */
.icon-panel-left-close:before {
  content: "\e805";
} /* '' */
.icon-external-link:before {
  content: "\e806";
} /* '' */
.icon-minus:before {
  content: "\e807";
} /* '' */
.icon-slack:before {
  content: "\e808";
} /* '' */
.icon-gpt:before {
  content: "\e809";
} /* '' */
.icon-x-social:before {
  content: "\e80a";
} /* '' */
.icon-search:before {
  content: "\e80b";
} /* '' */
.icon-hamburger-menu:before {
  content: "\e80c";
} /* '' */
.icon-linkedin:before {
  content: "\e80d";
} /* '' */
.icon-instagram:before {
  content: "\e80e";
} /* '' */
.icon-x:before {
  content: "\e80f";
} /* '' */
.icon-list-filter:before {
  content: "\e810";
} /* '' */
.icon-link:before {
  content: "\e811";
} /* '' */
.icon-bookmark-1:before {
  content: "\e812";
} /* '' */
.icon-cloud-download:before {
  content: "\e813";
} /* '' */
.icon-chevron-up:before {
  content: "\e814";
} /* '' */
.icon-chevron-down:before {
  content: "\e815";
} /* '' */
.icon-book-open-1:before {
  content: "\e816";
} /* '' */
.icon-quote:before {
  content: "\e817";
} /* '' */
.icon-check:before {
  content: "\e818";
} /* '' */
.icon-file-check:before {
  content: "\e819";
} /* '' */
.icon-microscope:before {
  content: "\e81a";
} /* '' */
.icon-calculator:before {
  content: "\e81b";
} /* '' */
.icon-users-round:before {
  content: "\e81c";
} /* '' */
.icon-copy:before {
  content: "\e81d";
} /* '' */
.icon-info:before {
  content: "\e81e";
} /* '' */
.icon-aicredit:before {
  content: "\e81f";
} /* '' */
.icon-credit-card:before {
  content: "\e820";
} /* '' */
.icon-ellipsis:before {
  content: "\e821";
} /* '' */
.icon-graduation-cap:before {
  content: "\e822";
} /* '' */
.icon-mail:before {
  content: "\e823";
} /* '' */
.icon-plus:before {
  content: "\e824";
} /* '' */
.icon-arrow-down:before {
  content: "\e825";
} /* '' */
.icon-arrow-left:before {
  content: "\e826";
} /* '' */
.icon-arrow-right:before {
  content: "\e827";
} /* '' */
.icon-arrow-up:before {
  content: "\e828";
} /* '' */
.icon-calendar:before {
  content: "\e829";
} /* '' */
.icon-file:before {
  content: "\e82a";
} /* '' */
.icon-ellipsis-vertical:before {
  content: "\e82b";
} /* '' */
.icon-clipboard-list:before {
  content: "\e82c";
} /* '' */
.icon-dashed-line:before {
  content: "\e82d";
} /* '' */
.icon-folder-open:before {
  content: "\e82e";
} /* '' */
.icon-bookmark-solid:before {
  content: "\e82f";
} /* '' */
.icon-code-xml:before {
  content: "\e830";
} /* '' */
.icon-clipboard:before {
  content: "\e831";
} /* '' */
.icon-log-out:before {
  content: "\e83d";
} /* '' */
.icon-user-round-cog:before {
  content: "\e83e";
} /* '' */
.icon-code-xml-1:before {
  content: "\e83f";
} /* '' */
.icon-map-pin:before {
  content: "\e840";
} /* '' */
.icon-scale:before {
  content: "\e841";
} /* '' */
.icon-timer:before {
  content: "\e842";
} /* '' */
.icon-file-search:before {
  content: "\e845";
} /* '' */
.icon-filter:before {
  content: "\e847";
} /* '' */
.icon-arrow-right-from-line:before {
  content: "\e848";
} /* '' */
.icon-settings:before {
  content: "\e849";
} /* '' */
.icon-table-2:before {
  content: "\e84a";
} /* '' */
.icon-toggle-right:before {
  content: "\e84b";
} /* '' */
.icon-user:before {
  content: "\e84e";
} /* '' */
.icon-text:before {
  content: "\e853";
} /* '' */
.icon-ticket:before {
  content: "\e854";
} /* '' */
.icon-list-filter-plus:before {
  content: "\e855";
} /* '' */
.icon-preprint:before {
  content: "\e856";
} /* '' */
.icon-trash-2:before {
  content: "\e859";
} /* '' */
.icon-pen-line:before {
  content: "\e85a";
} /* '' */
.icon-upload:before {
  content: "\e85d";
} /* '' */
.icon-file-arrow-up:before {
  content: "\e862";
} /* '' */
.icon-upload-file:before {
  content: "\e863";
} /* '' */
.icon-book-marked:before {
  content: "\e864";
} /* '' */
.icon-home-converted:before {
  content: "\e865";
} /* '' */
.icon-folder-converted:before {
  content: "\e866";
} /* '' */
.icon-traffic-cone:before {
  content: "\e869";
} /* '' */
.icon-refresh-ccw:before {
  content: "\e86a";
} /* '' */
.icon-construction:before {
  content: "\e86b";
} /* '' */
.icon-file-check-2:before {
  content: "\e86c";
} /* '' */
.icon-download:before {
  content: "\e86d";
} /* '' */
.icon-zoom-in:before {
  content: "\e86e";
} /* '' */
.icon-zoom-out:before {
  content: "\e86f";
} /* '' */
.icon-message-circle:before {
  content: "\e870";
} /* '' */
.icon-folder-open-1:before {
  content: "\e871";
} /* '' */
.icon-trash-1:before {
  content: "\e872";
} /* '' */
.icon-pen-line-1:before {
  content: "\e873";
} /* '' */
.icon-lock:before {
  content: "\e875";
} /* '' */
.icon-loader:before {
  content: "\e885";
} /* '' */
.icon-file-warning:before {
  content: "\e886";
} /* '' */
.icon-flag:before {
  content: "\e88b";
} /* '' */
.icon-triangle-alert:before {
  content: "\e88c";
} /* '' */
.icon-loader-circle:before {
  content: "\e89d";
} /* '' */
.icon-sun:before {
  content: "\e8a1";
} /* '' */
.icon-text-quote:before {
  content: "\e8a5";
} /* '' */
.icon-equal:before {
  content: "\e8b0";
} /* '' */
.icon-crown:before {
  content: "\e8b1";
} /* '' */
.icon-flask-conical:before {
  content: "\e8b2";
} /* '' */
.icon-list-checks:before {
  content: "\e8b3";
} /* '' */
.icon-book-open:before {
  content: "\e8b4";
} /* '' */
.icon-asterisk:before {
  content: "\e8b8";
} /* '' */
.icon-eye:before {
  content: "\e8c4";
} /* '' */
.icon-message-circle-plus:before {
  content: "\e91b";
} /* '' */
